import { faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Home = () => {
    return (
        <section className="py-16 bg-gray-100">
            <div className="px-4 lg:px-8">
                {/* Section Header */}
                <div className="text-center mb-12">
                    <h3 className="font-rubik  text-xl font-normal  text-orange-500  mb-2">About Us</h3>
                    <h2 className="font-rubik text-[40px] font-normal text-[#23214B]">Who are we, what we do?</h2>
                    <p className="font-rubik font-normal text-base text-[#222222] max-w-2xl mx-auto mt-4">
                        Discover how it works with simplified AI tools to sell your home. These user-friendly technologies make the process more efficient by automating tasks, providing data-driven insights.
                    </p>
                </div>

                {/* Grid of Feature Cards */}
                <div className="grid grid-cols-1 md:grid-cols-3 gap-10 xl:gap-1">
                    {/* Feature Card 1 */}
                    <div className="bg-white rounded-lg text-center">
                        <div className="flex flex-col gap-10 items-center lg:items-center text-center">
                            <div className="w-[90%] m-auto h-4 bg-[#C4C4C4]"></div>
                            <div className="flex flex-col items-center lg:items-center text-center xl:pt-4 xl:px-3">
                                <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M69.5318 2.04175C68.4565 2.04175 67.202 2.04175 66.1266 2.04175C58.7785 2.40019 51.7889 4.01319 45.1577 7.23918C37.0927 11.0028 30.2823 16.3795 24.9056 23.5484C21.142 28.3873 18.4536 33.764 16.4822 39.4991C16.303 39.8575 16.4822 40.0368 16.6614 40.216C18.2744 41.829 19.8874 43.442 21.5004 45.055C21.6796 45.2342 21.8589 45.5926 21.8589 45.9511C21.8589 46.3095 21.6796 46.668 21.3212 46.8472C20.9627 47.0264 20.4251 47.0264 20.2459 46.668C19.3497 45.7719 18.6329 45.055 17.7368 44.1589C16.6614 43.0835 15.7653 42.0082 14.69 41.1121C14.3315 40.7537 14.1523 40.216 14.3315 39.6783C16.6614 32.6887 20.0666 26.2367 24.9056 20.5016C29.7446 14.5873 35.6589 9.92751 42.2901 6.52229C48.3837 3.2963 55.0149 1.32486 61.8253 0.428753C64.6929 0.0703095 67.5604 -0.108912 70.4279 0.0703096C71.3241 0.0703096 71.5033 0.428753 71.6825 1.32486C72.0409 12.9743 69.1734 23.7276 62.9006 33.764C56.6279 43.6212 48.0252 50.9693 37.2719 55.6291C35.6589 56.3459 33.8667 56.8836 32.0745 57.6005C31.5368 57.7797 31.1784 57.7797 30.8199 57.2421C29.0277 55.4498 27.0563 53.4784 25.2641 51.6862C24.9056 51.3277 24.7264 50.7901 25.0848 50.2524C25.4433 49.7147 26.1602 49.7147 26.6978 50.2524C28.3108 51.8654 29.9238 53.4784 31.5368 55.0914C31.8953 55.4498 32.0745 55.4498 32.4329 55.2706C40.3187 52.5823 47.3083 48.281 53.2227 42.5459C60.2123 35.7354 64.8721 27.6705 67.5604 18.5301C68.9942 13.5119 69.7111 8.31451 69.7111 2.93786C69.7111 2.57941 69.5318 2.40019 69.5318 2.04175Z" fill="#FF0000" />
                                    <path d="M59.6746 23.0107C59.6746 29.1042 54.8356 33.9432 48.7421 33.9432C42.6486 33.9432 37.8096 29.1042 37.8096 23.0107C37.8096 17.0963 42.6486 12.0781 48.5629 12.0781C54.8356 12.0781 59.6746 16.9171 59.6746 23.0107ZM57.524 23.1899C57.524 18.1717 53.5811 14.2288 48.5629 14.2288C43.5447 14.2288 39.6018 18.1717 39.6018 23.1899C39.6018 28.2081 43.5447 32.151 48.5629 32.151C53.5811 31.9717 57.524 28.0289 57.524 23.1899Z" fill="#FF0000" />
                                    <path d="M32.2539 68.8915C33.5084 68.1746 34.763 67.2785 35.8383 66.3824C39.4228 63.1564 41.3942 59.2135 42.2903 54.5537C42.4695 53.8368 42.828 53.4784 43.3656 53.4784C44.0825 53.4784 44.441 54.0161 44.441 54.7329C44.2617 55.6291 44.0825 56.7044 43.7241 57.6005C41.7526 63.694 38.1682 68.1746 32.6123 71.2213C31.3578 71.9382 30.8201 71.5798 30.6409 70.3252C29.924 66.5616 29.2071 62.6187 28.4902 58.855C28.4902 58.4966 28.4902 58.1382 28.4902 57.9589C28.6695 57.6005 29.0279 57.4213 29.5656 57.4213C29.924 57.4213 30.2825 57.7797 30.4617 58.1382C30.6409 59.2135 30.8201 60.2888 31.1786 61.5434C31.3578 64.0525 31.8954 66.3824 32.2539 68.8915Z" fill="#FF0000" />
                                    <path d="M2.68219 39.3198C3.75752 39.499 4.83285 39.6782 5.72896 39.8574C8.23806 40.3951 10.7472 40.7536 13.077 41.2912C13.2563 41.2912 13.2563 41.2912 13.4355 41.2912C13.9732 41.4704 14.3316 42.0081 14.1524 42.5458C13.9732 43.0834 13.6147 43.4419 12.8978 43.2627C11.4641 42.9042 9.85106 42.725 8.41728 42.3666C6.0874 41.8289 3.75752 41.4704 1.24841 40.9328C1.06919 40.9328 1.06919 40.9328 0.88997 40.9328C-0.00613915 40.7536 -0.185361 40.2159 0.173083 39.3198C1.24841 37.3483 2.50297 35.3769 4.11596 33.7639C7.52117 30.1795 11.6433 28.208 16.303 27.1327C16.4823 27.1327 16.6615 27.1327 16.6615 26.9535C17.1992 26.7743 17.7368 27.1327 17.916 27.6704C18.0953 28.208 17.7368 28.7457 17.0199 28.9249C15.9446 29.2834 14.69 29.4626 13.6147 29.821C8.95495 31.434 5.37051 34.3016 2.68219 38.4237C2.86141 38.9613 2.86141 39.1406 2.68219 39.3198Z" fill="#FF0000" />
                                    <path d="M15.4067 55.2706C15.586 55.0913 15.7652 54.7329 15.9444 54.5537C19.3496 51.1485 22.5756 47.9225 25.9808 44.5173C27.2354 43.2627 28.3107 42.1874 29.5653 40.9328C30.1029 40.3952 30.6406 40.3952 31.1783 40.7536C31.5367 41.112 31.5367 41.8289 30.999 42.3666C27.0562 46.3095 23.1133 50.4316 18.9912 54.3745C18.4535 54.9121 17.9158 55.629 17.199 56.1667C16.8405 56.5251 16.4821 56.5251 16.1236 56.3459C15.586 56.1667 15.4067 55.8082 15.4067 55.2706Z" fill="#FF0000" />
                                    <path d="M10.7472 59.9303C11.1056 60.1095 11.4641 60.2887 11.6433 60.6472C11.8225 61.0056 11.6433 61.3641 11.4641 61.7225C11.2849 61.9017 10.9264 62.2602 10.7472 62.6186C8.59654 64.7693 6.44588 67.0992 4.11599 69.2498C3.75755 69.4291 3.57833 69.6083 3.21989 69.6083C2.86144 69.6083 2.503 69.429 2.32378 69.0706C2.14456 68.5329 2.14456 68.1745 2.503 67.8161C3.93677 66.3823 5.37055 64.9485 6.80432 63.5147C7.87965 62.4394 8.77576 61.5433 9.85109 60.468C10.2095 60.2887 10.3888 60.1095 10.7472 59.9303Z" fill="#FF0000" />
                                    <path d="M9.13408 52.5823C10.0302 52.5823 10.5679 53.4784 10.2094 54.016C10.0302 54.1953 10.0302 54.3745 9.85097 54.5537C7.52109 56.8836 5.1912 59.2135 2.86132 61.5434L2.6821 61.7226C2.14443 62.081 1.60677 62.2603 1.24832 61.7226C0.889881 61.3641 0.889881 60.6473 1.24832 60.2888C3.04054 58.4966 4.83276 56.7044 6.62498 54.9122C7.34186 54.1953 7.87953 53.6576 8.59642 53.1199C8.77564 52.7615 9.13408 52.5823 9.13408 52.5823Z" fill="#FF0000" />
                                    <path d="M19.1709 62.6188C19.1709 62.798 18.9917 62.9772 18.8125 63.3357C16.3034 65.8448 13.9735 68.1747 11.4644 70.6838C10.9267 71.2214 10.389 71.2214 9.85138 70.6838C9.49293 70.3253 9.49293 69.6084 10.0306 69.25C12.5397 66.7409 14.8696 64.411 17.3787 61.9019C17.7371 61.5435 18.0956 61.3642 18.6332 61.5435C18.9917 61.7227 19.1709 61.9019 19.1709 62.6188Z" fill="#FF0000" />
                                </svg>
                                <h3 className="text-poppins  text-xl font-normal  text-[#000000] mb-10 mt-20">Lorem Ipsum is simply</h3>
                                <p className="text-poppins  text-xl font-normal text-[#000000]  text-center ">
                                    Lorem Ipsum is simply dummy
                                    text of the printing and typesetting
                                    industry. Lorem Ipsum has been
                                    the industry's standard dummy
                                    text ever since the 1500s
                                </p>
                            </div>
                            <div className="w-[90%] m-auto h-4 bg-[#C4C4C4]"></div>
                        </div>
                    </div>

                    {/* Feature Card 2 */}

                    <div className="bg-[#F5F5F5] rounded-lg text-center">
                        <div className="flex flex-col gap-10 items-center lg:items-center text-center">
                            <div className="w-[90%] m-auto h-4 bg-[#C4C4C4]"></div>
                            <div className="flex flex-col items-center lg:items-center text-center xl:pt-4 ">
                                <svg width="59" height="72" viewBox="0 0 59 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M23.6573 49.1067C22.9404 49.2859 22.4028 49.4652 21.8651 49.8236C21.1482 48.7483 20.6106 47.4937 19.8937 46.5976C18.6391 44.8054 17.2054 43.1924 15.9508 41.4002C13.9794 38.7119 12.3664 36.0235 11.6495 32.6183C10.0365 24.1949 14.6962 15.9507 22.7612 12.7247C33.3353 8.42338 45.5224 15.5923 47.1354 26.8832C47.8523 32.0807 46.2393 36.382 43.3717 40.5041C42.1172 42.2963 40.5042 44.0885 39.2496 46.06C38.3535 47.3145 37.8159 48.5691 36.9198 49.8236C36.5613 50.3613 36.2029 50.8989 35.6652 51.0782C31.5431 52.6912 27.421 54.1249 23.2989 55.5587C22.7612 55.7379 22.4028 55.5587 22.2236 55.2003C22.0443 54.6626 22.0444 54.1249 22.582 53.9457C22.9405 53.7665 23.2989 53.5873 23.6573 53.5873C27.2417 52.3327 30.8262 51.0782 34.4106 49.6444C34.7691 49.4652 35.1275 49.1067 35.3067 48.7483C36.7405 46.06 38.5328 43.9093 40.325 41.4002C42.1172 39.0703 43.7302 36.9196 44.447 34.0521C46.2393 28.317 44.9847 23.2988 41.0418 18.9975C36.5613 13.9793 30.8262 12.3663 24.3742 14.3377C17.9223 16.3091 14.3378 20.7897 13.0832 27.4209C12.1871 32.0807 13.8001 36.0235 16.4884 39.608C18.1014 41.7586 19.7145 43.9093 21.3275 46.06C22.4028 46.7768 22.9404 47.8522 23.6573 49.1067Z" fill="#FF0000" />
                                    <path d="M22.7613 66.8496C22.5821 66.6704 22.2236 66.4912 22.2236 66.1327C22.2236 65.7743 22.4029 65.4159 22.5821 65.0574C22.5821 64.8782 22.9405 64.8782 23.1197 64.8782C27.0626 63.4444 31.1847 62.0106 35.1276 60.5769C36.0237 60.2184 36.3821 60.3976 36.5614 60.9353C36.7406 61.473 36.3821 62.0106 35.6653 62.3691C31.5432 63.8029 27.6003 65.2366 23.4782 66.8496C23.299 66.6704 23.1197 66.6704 22.7613 66.8496Z" fill="#FF0000" />
                                    <path d="M22.7613 60.9354C22.5821 60.7562 22.2236 60.577 22.2236 60.2186C22.2236 59.8601 22.4029 59.5017 22.5821 59.1432C22.5821 58.964 22.9405 58.964 23.1197 58.964C27.0626 57.5302 31.1847 56.0965 35.1276 54.6627C36.0237 54.3042 36.3821 54.4835 36.5614 55.0211C36.7406 55.5588 36.5614 56.0965 35.6653 56.4549C31.5432 57.8887 27.6003 59.5017 23.4782 60.9354C23.299 60.9354 23.1197 60.9354 22.7613 60.9354Z" fill="#FF0000" />
                                    <path d="M35.6652 65.9537C34.4106 70.2551 27.421 72.7642 23.1196 70.4343C27.2417 69.0005 31.3638 67.3875 35.6652 65.9537Z" fill="#FF0000" />
                                    <path d="M30.2888 3.22599C30.2888 3.94288 30.2888 4.65977 30.2888 5.37665C30.2888 5.91432 29.9304 6.27276 29.3927 6.27276C28.855 6.27276 28.4966 5.91432 28.4966 5.37665C28.4966 3.94288 28.4966 2.5091 28.4966 0.896109C28.4966 0.358443 28.855 0 29.3927 0C29.9304 0 30.2888 0.358443 30.2888 0.896109C30.2888 1.613 30.2888 2.5091 30.2888 3.22599Z" fill="#FF0000" />
                                    <path d="M3.22597 28.3171C3.94286 28.3171 4.65977 28.3171 5.37665 28.3171C6.09354 28.3171 6.45199 28.6756 6.45199 29.2132C6.45199 29.7509 6.09354 30.1094 5.37665 30.1094C3.94288 30.1094 2.50912 30.1094 1.07534 30.1094C0.358454 30.1094 0 29.7509 0 29.2132C0 28.6756 0.358454 28.3171 1.07534 28.3171C1.79223 28.3171 2.50908 28.3171 3.22597 28.3171Z" fill="#FF0000" />
                                    <path d="M55.5585 30.2886C54.8416 30.2886 54.1247 30.2886 53.4078 30.2886C52.8702 30.2886 52.5117 29.9301 52.5117 29.3924C52.5117 28.8548 52.8702 28.4963 53.4078 28.4963C54.8416 28.4963 56.4546 28.4963 57.8884 28.4963C58.426 28.4963 58.7845 28.8548 58.7845 29.3924C58.7845 29.9301 58.426 30.2886 57.8884 30.2886C56.9923 30.2886 56.2754 30.2886 55.5585 30.2886Z" fill="#FF0000" />
                                    <path d="M8.96107 7.88586C9.31951 8.06509 9.49872 8.24431 9.67794 8.42353C10.7533 9.49886 11.6494 10.395 12.7247 11.4703C13.2624 12.008 13.2624 12.3664 12.7247 12.9041C12.3663 13.2625 11.8286 13.2625 11.2909 12.9041C10.2156 11.8287 9.1403 10.9326 8.24419 9.8573C8.06497 9.67808 7.88573 9.14042 8.06496 8.78197C8.24418 8.24431 8.78184 8.06509 8.96107 7.88586Z" fill="#FF0000" />
                                    <path d="M46.4186 45.3431C46.777 45.5224 46.9562 45.7016 47.1354 45.8808C48.2108 46.7769 49.1069 47.8522 50.1822 48.7484C50.7199 49.286 50.7199 49.8237 50.3615 50.1821C50.003 50.5406 49.4653 50.5406 48.9276 50.0029C47.8523 48.9276 46.9562 48.0315 45.8809 46.9561C45.7017 46.7769 45.5225 46.2393 45.7017 45.8808C45.7017 45.7016 46.0601 45.5224 46.4186 45.3431Z" fill="#FF0000" />
                                    <path d="M50.7196 8.96119C50.5404 9.31963 50.3612 9.49885 50.182 9.67808C49.1067 10.7534 48.2105 11.6495 47.1352 12.7248C46.7768 13.0833 46.2391 13.2625 45.7014 12.7248C45.343 12.3664 45.343 11.8287 45.7014 11.2911C46.7768 10.2157 47.6729 9.14041 48.7482 8.2443C48.9274 8.06508 49.4651 7.88586 49.8235 8.06508C50.182 8.2443 50.3612 8.78197 50.7196 8.96119Z" fill="#FF0000" />
                                    <path d="M8.96108 50.5406C8.78186 50.3614 8.42343 50.1821 8.2442 49.8237C8.06498 49.6445 8.2442 49.1068 8.2442 48.9276C9.31954 47.8523 10.3949 46.5977 11.6494 45.5224C12.0079 45.1639 12.5455 45.1639 12.9039 45.5224C13.2624 45.8808 13.2624 46.4185 12.9039 46.7769C11.8286 47.8523 10.7533 48.9276 9.67796 50.0029C9.49874 50.3614 9.31952 50.5406 8.96108 50.5406Z" fill="#FF0000" />
                                </svg>
                                <h3 className="text-poppins  text-xl font-normal  text-[#000000] mb-10 mt-20">Lorem Ipsum is simply</h3>
                                <p className="text-poppins  text-xl font-normal text-[#000000]  text-center ">
                                    Lorem Ipsum is simply dummy
                                    text of the printing and typesetting
                                    industry. Lorem Ipsum has been
                                    the industry's standard dummy
                                    text ever since the 1500s
                                </p>
                            </div>
                            <div className="w-[90%] m-auto h-4 bg-[#C4C4C4]"></div>
                        </div>
                    </div>

                    {/* Feature Card 3 */}
                    <div className="bg-white rounded-lg text-center">
                        <div className="flex flex-col gap-10 items-center lg:items-center text-center">
                            <div className="w-[90%] m-auto h-4 bg-[#C4C4C4]"></div>
                            <div className="flex flex-col items-center lg:items-center text-center xl:pt-4 xl:px-3">
                                <svg width="59" height="72" viewBox="0 0 72 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M13.0832 11.2911C12.5455 11.6496 12.1871 12.008 11.4702 12.3665C9.67802 13.2626 7.88576 13.8002 6.27276 14.8756C3.04677 17.0262 3.04673 19.7145 6.45194 21.5068C9.85715 23.299 13.4416 24.5535 17.2052 25.2704C21.865 26.1665 26.5248 26.525 31.3638 27.0626C33.3352 27.2419 35.3067 27.0626 37.4574 27.0626C37.4574 25.6289 37.6366 24.1951 37.6366 22.4029C39.7872 24.912 41.5794 27.0626 43.5509 29.2133C41.9379 30.8263 39.9664 32.7977 37.6366 35.3068C37.6366 33.5146 37.4574 32.2601 37.4574 31.0055C32.26 30.6471 27.0625 30.2886 22.0443 29.751C16.1299 29.0341 10.3948 27.9587 5.01819 25.0912C2.6883 23.8366 0 22.4029 0 19.3561C0 16.1301 2.6883 14.6963 5.01819 13.4418C7.34807 12.1872 9.85724 11.4704 12.3663 10.395C12.7248 10.7535 12.9039 11.1119 13.0832 11.2911Z" fill="#FF0000" />
                                    <path d="M46.9558 30.1093C46.5973 26.5249 46.5973 26.7041 49.4648 26.1665C54.1246 25.0911 58.7844 23.8366 63.2649 22.4028C65.2364 21.8651 67.2078 20.4314 67.2078 18.1015C67.2078 15.7716 65.0572 14.8755 63.4442 13.9794C62.1896 13.2625 60.935 12.9041 59.6805 12.3664C59.1428 12.1872 58.7844 11.6495 58.6052 10.9326C62.9065 11.8287 66.8493 12.9041 70.0753 16.13C72.0468 18.1015 72.0468 20.4314 70.0753 22.4028C69 23.4781 67.7455 24.3742 66.3117 25.0911C60.3974 28.1379 53.7662 29.2132 46.9558 30.1093Z" fill="#FF0000" />
                                    <path d="M59.1434 0C57.3512 0 55.9175 1.43377 55.9175 3.22599C55.9175 5.01821 57.3512 6.45198 59.1434 6.45198C60.9357 6.45198 62.3694 5.01821 62.3694 3.22599C62.3694 1.43377 60.9357 0 59.1434 0ZM59.1434 4.48054C58.4266 4.48054 57.7097 3.94288 57.7097 3.04677C57.7097 2.32988 58.2473 1.613 59.1434 1.613C59.8603 1.613 60.5772 2.15066 60.5772 3.04677C60.5772 3.94288 59.8603 4.48054 59.1434 4.48054Z" fill="#FF0000" />
                                    <path d="M27.0624 13.6208C27.0624 16.6676 24.7325 18.9974 21.5065 18.9974C18.4598 18.9974 16.1299 16.8468 16.1299 13.8H18.8182C18.9974 15.413 20.0727 16.4883 21.6857 16.4883C23.2987 16.4883 24.5533 15.2338 24.5533 13.4416C24.5533 11.6493 23.2988 10.574 21.3274 10.574H19.3559V10.0363L22.7611 4.83892H16.6675V2.50903H26.704V3.0467L23.1196 8.60257C25.2702 9.14024 27.0624 11.1117 27.0624 13.6208Z" fill="#FF0000" />
                                    <path d="M41.2207 13.4416C41.2207 16.6676 38.7116 19.1767 35.3064 19.1767C31.722 19.1767 29.3921 16.8468 29.3921 13.4416C29.3921 12.0078 29.9298 10.2156 31.1843 8.06493L34.4103 2.32983H37.2778C35.3063 5.73505 34.5895 7.16882 33.8726 8.42337C34.5895 8.24415 35.1271 8.06493 35.844 8.06493C38.7116 8.06493 41.2207 10.2156 41.2207 13.4416ZM38.5324 13.4416C38.5324 11.4701 37.0986 10.2156 35.3064 10.2156C33.3349 10.2156 32.0804 11.4701 32.0804 13.4416C32.0804 15.2338 33.5142 16.6676 35.3064 16.6676C37.0986 16.6676 38.5324 15.2338 38.5324 13.4416Z" fill="#FF0000" />
                                    <path d="M43.0132 10.5741C43.0132 5.37662 45.5222 2.15063 49.4651 2.15063C53.408 2.15063 55.9172 5.37662 55.9172 10.5741C55.9172 15.7715 53.408 18.9975 49.4651 18.9975C45.5222 18.9975 43.0132 15.7715 43.0132 10.5741ZM53.2288 10.5741C53.2288 6.8104 51.795 4.65974 49.4651 4.65974C47.1352 4.65974 45.7015 6.8104 45.7015 10.5741C45.7015 14.3377 47.1352 16.4884 49.4651 16.4884C51.795 16.6676 53.2288 14.3377 53.2288 10.5741Z" fill="#FF0000" />
                                </svg>
                                <h3 className="text-poppins  text-xl font-normal  text-[#000000] mb-10 mt-20">Lorem Ipsum is simply</h3>
                                <p className="text-poppins  text-xl font-normal text-[#000000]  text-center ">
                                    Lorem Ipsum is simply dummy
                                    text of the printing and typesetting
                                    industry. Lorem Ipsum has been
                                    the industry's standard dummy
                                    text ever since the 1500s
                                </p>
                            </div>
                            <div className="w-[90%] m-auto h-4 bg-[#C4C4C4]"></div>
                        </div>
                    </div>

                </div>

                {/* Another Row of Features Below (Optional) */}
                <div className=" xl:pt-12">

                    <div className=" xl:w-[60vw]  xl:m-auto grid grid-cols-1 md:grid-cols-2 gap-8 mt-12">
                        {/* Feature Card 4 */}
                        {/* <div className="flex items-start">
                        <FontAwesomeIcon icon={faUsers} className="w-12 h-12 mx-auto mb-4" />
                        <p className="text-gray-600">
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
                        </p>
                    </div> */}
                        <div className="flex flex-col items-center lg:items-start">
                            <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M69.5318 2.04175C68.4565 2.04175 67.202 2.04175 66.1266 2.04175C58.7785 2.40019 51.7889 4.01319 45.1577 7.23918C37.0927 11.0028 30.2823 16.3795 24.9056 23.5484C21.142 28.3873 18.4536 33.764 16.4822 39.4991C16.303 39.8575 16.4822 40.0368 16.6614 40.216C18.2744 41.829 19.8874 43.442 21.5004 45.055C21.6796 45.2342 21.8589 45.5926 21.8589 45.9511C21.8589 46.3095 21.6796 46.668 21.3212 46.8472C20.9627 47.0264 20.4251 47.0264 20.2459 46.668C19.3497 45.7719 18.6329 45.055 17.7368 44.1589C16.6614 43.0835 15.7653 42.0082 14.69 41.1121C14.3315 40.7537 14.1523 40.216 14.3315 39.6783C16.6614 32.6887 20.0666 26.2367 24.9056 20.5016C29.7446 14.5873 35.6589 9.92751 42.2901 6.52229C48.3837 3.2963 55.0149 1.32486 61.8253 0.428753C64.6929 0.0703095 67.5604 -0.108912 70.4279 0.0703096C71.3241 0.0703096 71.5033 0.428753 71.6825 1.32486C72.0409 12.9743 69.1734 23.7276 62.9006 33.764C56.6279 43.6212 48.0252 50.9693 37.2719 55.6291C35.6589 56.3459 33.8667 56.8836 32.0745 57.6005C31.5368 57.7797 31.1784 57.7797 30.8199 57.2421C29.0277 55.4498 27.0563 53.4784 25.2641 51.6862C24.9056 51.3277 24.7264 50.7901 25.0848 50.2524C25.4433 49.7147 26.1602 49.7147 26.6978 50.2524C28.3108 51.8654 29.9238 53.4784 31.5368 55.0914C31.8953 55.4498 32.0745 55.4498 32.4329 55.2706C40.3187 52.5823 47.3083 48.281 53.2227 42.5459C60.2123 35.7354 64.8721 27.6705 67.5604 18.5301C68.9942 13.5119 69.7111 8.31451 69.7111 2.93786C69.7111 2.57941 69.5318 2.40019 69.5318 2.04175Z" fill="#FEC536" />
                                <path d="M59.6746 23.0107C59.6746 29.1042 54.8356 33.9432 48.7421 33.9432C42.6486 33.9432 37.8096 29.1042 37.8096 23.0107C37.8096 17.0963 42.6486 12.0781 48.5629 12.0781C54.8356 12.0781 59.6746 16.9171 59.6746 23.0107ZM57.524 23.1899C57.524 18.1717 53.5811 14.2288 48.5629 14.2288C43.5447 14.2288 39.6018 18.1717 39.6018 23.1899C39.6018 28.2081 43.5447 32.151 48.5629 32.151C53.5811 31.9717 57.524 28.0289 57.524 23.1899Z" fill="#FEC536" />
                                <path d="M32.2539 68.8915C33.5084 68.1746 34.763 67.2785 35.8383 66.3824C39.4228 63.1564 41.3942 59.2135 42.2903 54.5537C42.4695 53.8368 42.828 53.4784 43.3656 53.4784C44.0825 53.4784 44.441 54.0161 44.441 54.7329C44.2617 55.6291 44.0825 56.7044 43.7241 57.6005C41.7526 63.694 38.1682 68.1746 32.6123 71.2213C31.3578 71.9382 30.8201 71.5798 30.6409 70.3252C29.924 66.5616 29.2071 62.6187 28.4902 58.855C28.4902 58.4966 28.4902 58.1382 28.4902 57.9589C28.6695 57.6005 29.0279 57.4213 29.5656 57.4213C29.924 57.4213 30.2825 57.7797 30.4617 58.1382C30.6409 59.2135 30.8201 60.2888 31.1786 61.5434C31.3578 64.0525 31.8954 66.3824 32.2539 68.8915Z" fill="#FEC536" />
                                <path d="M2.68219 39.3198C3.75752 39.499 4.83285 39.6782 5.72896 39.8574C8.23806 40.3951 10.7472 40.7536 13.077 41.2912C13.2563 41.2912 13.2563 41.2912 13.4355 41.2912C13.9732 41.4704 14.3316 42.0081 14.1524 42.5458C13.9732 43.0834 13.6147 43.4419 12.8978 43.2627C11.4641 42.9042 9.85106 42.725 8.41728 42.3666C6.0874 41.8289 3.75752 41.4704 1.24841 40.9328C1.06919 40.9328 1.06919 40.9328 0.88997 40.9328C-0.00613915 40.7536 -0.185361 40.2159 0.173083 39.3198C1.24841 37.3483 2.50297 35.3769 4.11596 33.7639C7.52117 30.1795 11.6433 28.208 16.303 27.1327C16.4823 27.1327 16.6615 27.1327 16.6615 26.9535C17.1992 26.7743 17.7368 27.1327 17.916 27.6704C18.0953 28.208 17.7368 28.7457 17.0199 28.9249C15.9446 29.2834 14.69 29.4626 13.6147 29.821C8.95495 31.434 5.37051 34.3016 2.68219 38.4237C2.86141 38.9613 2.86141 39.1406 2.68219 39.3198Z" fill="#FEC536" />
                                <path d="M15.4067 55.2706C15.586 55.0913 15.7652 54.7329 15.9444 54.5537C19.3496 51.1485 22.5756 47.9225 25.9808 44.5173C27.2354 43.2627 28.3107 42.1874 29.5653 40.9328C30.1029 40.3952 30.6406 40.3952 31.1783 40.7536C31.5367 41.112 31.5367 41.8289 30.999 42.3666C27.0562 46.3095 23.1133 50.4316 18.9912 54.3745C18.4535 54.9121 17.9158 55.629 17.199 56.1667C16.8405 56.5251 16.4821 56.5251 16.1236 56.3459C15.586 56.1667 15.4067 55.8082 15.4067 55.2706Z" fill="#FEC536" />
                                <path d="M10.7472 59.9304C11.1056 60.1096 11.4641 60.2889 11.6433 60.6473C11.8225 61.0057 11.6433 61.3642 11.4641 61.7226C11.2849 61.9019 10.9264 62.2603 10.7472 62.6187C8.59654 64.7694 6.44588 67.0993 4.11599 69.25C3.75755 69.4292 3.57833 69.6084 3.21989 69.6084C2.86144 69.6084 2.503 69.4292 2.32378 69.0707C2.14456 68.5331 2.14456 68.1746 2.503 67.8162C3.93677 66.3824 5.37055 64.9486 6.80432 63.5149C7.87965 62.4395 8.77576 61.5434 9.85109 60.4681C10.2095 60.2889 10.3888 60.1096 10.7472 59.9304Z" fill="#FEC536" />
                                <path d="M9.13408 52.5823C10.0302 52.5823 10.5679 53.4784 10.2094 54.016C10.0302 54.1953 10.0302 54.3745 9.85097 54.5537C7.52109 56.8836 5.1912 59.2135 2.86132 61.5434L2.6821 61.7226C2.14443 62.081 1.60677 62.2603 1.24832 61.7226C0.889881 61.3641 0.889881 60.6473 1.24832 60.2888C3.04054 58.4966 4.83276 56.7044 6.62498 54.9122C7.34186 54.1953 7.87953 53.6576 8.59642 53.1199C8.77564 52.7615 9.13408 52.5823 9.13408 52.5823Z" fill="#FEC536" />
                                <path d="M19.1704 62.6188C19.1704 62.798 18.9912 62.9772 18.812 63.3357C16.3029 65.8448 13.973 68.1747 11.4639 70.6838C10.9262 71.2214 10.3886 71.2214 9.85089 70.6838C9.49244 70.3253 9.49244 69.6084 10.0301 69.25C12.5392 66.7409 14.8691 64.411 17.3782 61.9019C17.7366 61.5435 18.0951 61.3642 18.6328 61.5435C18.9912 61.7227 19.1704 61.9019 19.1704 62.6188Z" fill="#FEC536" />
                            </svg>
                            <p className="text-poppins text-[#000000] text-xl font-normal   text-center lg:text-left mt-5">
                                Lorem Ipsum is simply dummy
                                text of the printing and typesetting
                                industry. Lorem Ipsum has been
                                the industry's standard dummy
                                text ever since the 1500s,
                            </p>
                        </div>

                        {/* Feature Card 5 */}
                        <div className="flex flex-col items-center lg:items-start">
                            <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M69.5318 2.04175C68.4565 2.04175 67.202 2.04175 66.1266 2.04175C58.7785 2.40019 51.7889 4.01319 45.1577 7.23918C37.0927 11.0028 30.2823 16.3795 24.9056 23.5484C21.142 28.3873 18.4536 33.764 16.4822 39.4991C16.303 39.8575 16.4822 40.0368 16.6614 40.216C18.2744 41.829 19.8874 43.442 21.5004 45.055C21.6796 45.2342 21.8589 45.5926 21.8589 45.9511C21.8589 46.3095 21.6796 46.668 21.3212 46.8472C20.9627 47.0264 20.4251 47.0264 20.2459 46.668C19.3497 45.7719 18.6329 45.055 17.7368 44.1589C16.6614 43.0835 15.7653 42.0082 14.69 41.1121C14.3315 40.7537 14.1523 40.216 14.3315 39.6783C16.6614 32.6887 20.0666 26.2367 24.9056 20.5016C29.7446 14.5873 35.6589 9.92751 42.2901 6.52229C48.3837 3.2963 55.0149 1.32486 61.8253 0.428753C64.6929 0.0703095 67.5604 -0.108912 70.4279 0.0703096C71.3241 0.0703096 71.5033 0.428753 71.6825 1.32486C72.0409 12.9743 69.1734 23.7276 62.9006 33.764C56.6279 43.6212 48.0252 50.9693 37.2719 55.6291C35.6589 56.3459 33.8667 56.8836 32.0745 57.6005C31.5368 57.7797 31.1784 57.7797 30.8199 57.2421C29.0277 55.4498 27.0563 53.4784 25.2641 51.6862C24.9056 51.3277 24.7264 50.7901 25.0848 50.2524C25.4433 49.7147 26.1602 49.7147 26.6978 50.2524C28.3108 51.8654 29.9238 53.4784 31.5368 55.0914C31.8953 55.4498 32.0745 55.4498 32.4329 55.2706C40.3187 52.5823 47.3083 48.281 53.2227 42.5459C60.2123 35.7354 64.8721 27.6705 67.5604 18.5301C68.9942 13.5119 69.7111 8.31451 69.7111 2.93786C69.7111 2.57941 69.5318 2.40019 69.5318 2.04175Z" fill="#FEC536" />
                                <path d="M59.6746 23.0107C59.6746 29.1042 54.8356 33.9432 48.7421 33.9432C42.6486 33.9432 37.8096 29.1042 37.8096 23.0107C37.8096 17.0963 42.6486 12.0781 48.5629 12.0781C54.8356 12.0781 59.6746 16.9171 59.6746 23.0107ZM57.524 23.1899C57.524 18.1717 53.5811 14.2288 48.5629 14.2288C43.5447 14.2288 39.6018 18.1717 39.6018 23.1899C39.6018 28.2081 43.5447 32.151 48.5629 32.151C53.5811 31.9717 57.524 28.0289 57.524 23.1899Z" fill="#FEC536" />
                                <path d="M32.2539 68.8915C33.5084 68.1746 34.763 67.2785 35.8383 66.3824C39.4228 63.1564 41.3942 59.2135 42.2903 54.5537C42.4695 53.8368 42.828 53.4784 43.3656 53.4784C44.0825 53.4784 44.441 54.0161 44.441 54.7329C44.2617 55.6291 44.0825 56.7044 43.7241 57.6005C41.7526 63.694 38.1682 68.1746 32.6123 71.2213C31.3578 71.9382 30.8201 71.5798 30.6409 70.3252C29.924 66.5616 29.2071 62.6187 28.4902 58.855C28.4902 58.4966 28.4902 58.1382 28.4902 57.9589C28.6695 57.6005 29.0279 57.4213 29.5656 57.4213C29.924 57.4213 30.2825 57.7797 30.4617 58.1382C30.6409 59.2135 30.8201 60.2888 31.1786 61.5434C31.3578 64.0525 31.8954 66.3824 32.2539 68.8915Z" fill="#FEC536" />
                                <path d="M2.68219 39.3198C3.75752 39.499 4.83285 39.6782 5.72896 39.8574C8.23806 40.3951 10.7472 40.7536 13.077 41.2912C13.2563 41.2912 13.2563 41.2912 13.4355 41.2912C13.9732 41.4704 14.3316 42.0081 14.1524 42.5458C13.9732 43.0834 13.6147 43.4419 12.8978 43.2627C11.4641 42.9042 9.85106 42.725 8.41728 42.3666C6.0874 41.8289 3.75752 41.4704 1.24841 40.9328C1.06919 40.9328 1.06919 40.9328 0.88997 40.9328C-0.00613915 40.7536 -0.185361 40.2159 0.173083 39.3198C1.24841 37.3483 2.50297 35.3769 4.11596 33.7639C7.52117 30.1795 11.6433 28.208 16.303 27.1327C16.4823 27.1327 16.6615 27.1327 16.6615 26.9535C17.1992 26.7743 17.7368 27.1327 17.916 27.6704C18.0953 28.208 17.7368 28.7457 17.0199 28.9249C15.9446 29.2834 14.69 29.4626 13.6147 29.821C8.95495 31.434 5.37051 34.3016 2.68219 38.4237C2.86141 38.9613 2.86141 39.1406 2.68219 39.3198Z" fill="#FEC536" />
                                <path d="M15.4067 55.2706C15.586 55.0913 15.7652 54.7329 15.9444 54.5537C19.3496 51.1485 22.5756 47.9225 25.9808 44.5173C27.2354 43.2627 28.3107 42.1874 29.5653 40.9328C30.1029 40.3952 30.6406 40.3952 31.1783 40.7536C31.5367 41.112 31.5367 41.8289 30.999 42.3666C27.0562 46.3095 23.1133 50.4316 18.9912 54.3745C18.4535 54.9121 17.9158 55.629 17.199 56.1667C16.8405 56.5251 16.4821 56.5251 16.1236 56.3459C15.586 56.1667 15.4067 55.8082 15.4067 55.2706Z" fill="#FEC536" />
                                <path d="M10.7472 59.9304C11.1056 60.1096 11.4641 60.2889 11.6433 60.6473C11.8225 61.0057 11.6433 61.3642 11.4641 61.7226C11.2849 61.9019 10.9264 62.2603 10.7472 62.6187C8.59654 64.7694 6.44588 67.0993 4.11599 69.25C3.75755 69.4292 3.57833 69.6084 3.21989 69.6084C2.86144 69.6084 2.503 69.4292 2.32378 69.0707C2.14456 68.5331 2.14456 68.1746 2.503 67.8162C3.93677 66.3824 5.37055 64.9486 6.80432 63.5149C7.87965 62.4395 8.77576 61.5434 9.85109 60.4681C10.2095 60.2889 10.3888 60.1096 10.7472 59.9304Z" fill="#FEC536" />
                                <path d="M9.13408 52.5823C10.0302 52.5823 10.5679 53.4784 10.2094 54.016C10.0302 54.1953 10.0302 54.3745 9.85097 54.5537C7.52109 56.8836 5.1912 59.2135 2.86132 61.5434L2.6821 61.7226C2.14443 62.081 1.60677 62.2603 1.24832 61.7226C0.889881 61.3641 0.889881 60.6473 1.24832 60.2888C3.04054 58.4966 4.83276 56.7044 6.62498 54.9122C7.34186 54.1953 7.87953 53.6576 8.59642 53.1199C8.77564 52.7615 9.13408 52.5823 9.13408 52.5823Z" fill="#FEC536" />
                                <path d="M19.1704 62.6188C19.1704 62.798 18.9912 62.9772 18.812 63.3357C16.3029 65.8448 13.973 68.1747 11.4639 70.6838C10.9262 71.2214 10.3886 71.2214 9.85089 70.6838C9.49244 70.3253 9.49244 69.6084 10.0301 69.25C12.5392 66.7409 14.8691 64.411 17.3782 61.9019C17.7366 61.5435 18.0951 61.3642 18.6328 61.5435C18.9912 61.7227 19.1704 61.9019 19.1704 62.6188Z" fill="#FEC536" />
                            </svg>



                            <p className="text-poppins text-[#000000] text-xl font-normal  text-center lg:text-left mt-5">
                                Lorem Ipsum is simply dummy
                                text of the printing and typesetting
                                industry. Lorem Ipsum has been
                                the industry's standard dummy
                                text ever since the 1500s,
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Home;
