import { useState } from 'react';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <header className="bg-white shadow-md">
      <div className="container mx-auto flex justify-between items-center py-4 px-4 xl:px-0">
        {/* Logo */}
        <div className="flex items-center">
          <img src="images/logo.png" alt="Logo" className="w-10 h-10 mr-2" />
          <span className="text-xl font-bold text-gray-800">SelfSellPro</span>
        </div>

        {/* Hamburger Menu (Mobile) */}
        <div className="xl:hidden">
          <button onClick={() => setIsOpen(!isOpen)} className="focus:outline-none text-gray-600">
            {isOpen ? (
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            ) : (
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7" />
              </svg>
            )}
          </button>
        </div>

        {/* Navigation Links (Desktop) */}
        <nav className="hidden xl:flex space-x-4">
          <a href="/" className="text-gray-600 hover:text-orange-500">Home</a>
          <a href="/how-it-works" className="text-gray-600 hover:text-orange-500">How it works</a>
          <a href="/features" className="text-gray-600 hover:text-orange-500">Features</a>
          <a href="/pricing" className="text-gray-600 hover:text-orange-500">Pricing</a>
          <a href="/resources" className="text-gray-600 hover:text-orange-500">Resources</a>
          <a href="/about" className="text-gray-600 hover:text-orange-500">About Us</a>
          <a href="/contact" className="text-gray-600 hover:text-orange-500">Contact</a>
        </nav>

        {/* Search Bar (Hidden on Mobile, Shown on Desktop) */}
        {/* <div className="hidden xl:flex items-center relative ">
          <input
            type="text"
            placeholder="Search resources, blog..."
            className="border border-gray-300 rounded-full py-2 px-4 w-64 focus:outline-none focus:border-orange-500"
          />
          <button className="absolute right-2  bg-orange-600  text-white rounded-[180px]">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-7 w-7" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
            </svg>
          </button>
        </div> */}
        <div className="hidden relative  xl:flex md:items-center md:gap-8 md:bg-white md:rounded-full md:shadow-md md:pl-2.5 md:pr-2 md:py-1.5 md:border md:border-[#6F6F6F] md:w-xl ">
                    <input
                        type="text"
                        placeholder="Search resources, blog posts, FAQs..."
                        className="flex-grow w-[15vw]  p-1 lg:pl-2 text-gray-700 focus:outline-none rounded-full text-sm"
                    />
                    <button className="bg-orange-500 text-white px-2  py-1 rounded-full">
                        <FontAwesomeIcon icon={faSearch} />
                    </button>
                </div>

        {/* Buttons (Desktop Only) */}
        <div className="hidden xl:flex space-x-3">
          <a href="/property-details" className="px-6 py-3 bg-orange-500 text-white rounded hover:bg-orange-600">
            List Your Home
          </a>
          <button className="bg-white border border-gray-400 text-gray-600 px-4 py-2 rounded hover:bg-gray-200">
            Contact Us
          </button>
        </div>
      </div>

      {/* Mobile Menu (Visible when Hamburger is Open) */}
      {isOpen && (
        <nav className="xl:hidden bg-white px-4 pb-4">
          <a href="/" className="block py-2 text-gray-600 hover:text-orange-500">Home</a>
          <a href="/how-it-works" className="block py-2 text-gray-600 hover:text-orange-500">How it works</a>
          <a href="/features" className="block py-2 text-gray-600 hover:text-orange-500">Features</a>
          <a href="/pricing" className="block py-2 text-gray-600 hover:text-orange-500">Pricing</a>
          <a href="/resources" className="block py-2 text-gray-600 hover:text-orange-500">Resources</a>
          <a href="/about" className="block py-2 text-gray-600 hover:text-orange-500">About Us</a>
          <a href="/contact" className="block py-2 text-gray-600 hover:text-orange-500">Contact</a>

          <div className="flex flex-col space-y-4 mt-4">
            <a href="/property-details" className="border border-orange-500 text-orange-500 px-4 py-2 rounded-full hover:bg-orange-100">
              List Your Home
            </a>
            <button className="bg-white border border-gray-400 text-gray-600 px-4 py-2 rounded-full hover:bg-gray-200">
              Contact Us
            </button>
          </div>
        </nav>
      )}
    </header>
  );
};

export default Header;
