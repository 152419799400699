import React from 'react';
import Ellipse from '../Asserts/Ellipse.png';
import TestimonialCard from './TestimonialCard';

const Testimonials = () => {
  const testimonials = [
    {
      name: "Sarah T.",
      location: "Houston, TX",
      image: Ellipse, // Replace with the actual image URL
      review: "Thanks to SelfSellPro's pricing, I sold my home for $20,000 more than I expected, and in just 10 days!",
      rating: 5,
      tag: "First-time Seller",
      tagColor: "text-custom-green"
    },
    {
      name: "Sarah T.",
      location: "Houston, TX",
      image: Ellipse, // Replace with the actual image URL
      review: "Thanks to SelfSellPro's pricing, I sold my home for $20,000 more than I expected, and in just 10 days!",
      rating: 5,
      tag: "Saved $15,000 in fees",
      tagColor: "text-custom-orange"
    },
    {
      name: "Sarah T.",
      location: "Houston, TX",
      image: Ellipse, // Replace with the actual image URL
      review: "Thanks to SelfSellPro's pricing, I sold my home for $20,000 more than I expected, and in just 10 days!",
      rating: 5,
      tag: "First-time Seller",
      tagColor: "text-custom-green"
    }
  ];

  return (
    <div>
        <TestimonialCard testimonial={testimonials}/>
    </div>
  );
};

export default Testimonials;