

const Home = () => {
    return (
        <section>
            <div className="lg:px-0 font-roboto">
                {/* Section Header */}
                <div className="text-center py-8 md:py-10 lg:py-12 bg-[#F5F5F5] font-roboto font-normal">
                    <h3 className="text-[#FF9357] text-lg md:text-xl  mb-2">Pricing</h3>
                    <h2 className="text-3xl md:text-4xl lg:text-[40px] text-[#23214B]">Pricing Based on your needs</h2>
                    <p className="text-[#222222] max-w-xl mx-auto mt-4 text-sm md:text-base lg:text-[16px]">
                        Discover how it works with simplified AI tools to sell your home. These user-friendly technologies make the process more efficient by automating tasks, providing data-driven insights.
                    </p>
                </div>

                <div className="bg-[#F2F7FF] py-8 px-4 md:py-12 lg:py-16">
                    {/* Pricing Cards */}
                    <div className="space-y-8 w-full md:w-[80vw] lg:w-[65vw] mx-auto">
                        {/* Pricing Card 1 */}
                        <div className="bg-white p-6 md:p-10 lg:p-12 rounded-lg shadow-lg flex flex-col xl:flex-row gap-4 xl:gap-0 justify-between items-center">
                            <div className="w-full xl:w-1/2 mb-6 lg:mb-0">
                                <h3 className="text-2xl md:text-3xl lg:text-[34px] font-bold text-gray-900 mb-2">AI-Essentials (Basic)</h3>
                                <p className="text-gray-600 text-lg md:text-xl lg:text-2xl font-normal">AI Pricing, Basic Listing, Email Support</p>
                            </div>
                            <div className="text-center lg:text-right">
                                <h4 className="text-4xl md:text-6xl xl:text-7xl font-bold text-gray-900 mb-4 text-center">$299</h4>
                                <button className="px-6 py-2 text-lg md:text-xl lg:text-[22px] bg-blue-500 text-white rounded hover:bg-blue-600">
                                    Purchase Now
                                </button>
                            </div>
                        </div>

                        {/* Pricing Card 2 */}
                        <div className="bg-white p-6 md:p-10 lg:p-12 rounded-lg shadow-lg flex flex-col xl:flex-row gap-4 xl:gap-0 justify-between items-center">
                            <div className="w-full xl:w-1/2 mb-6 lg:mb-0">
                                <h3 className="text-2xl md:text-3xl lg:text-[34px] font-bold text-gray-900 mb-2">AI-PRO (Pro)</h3>
                                <p className="text-gray-600 text-lg md:text-xl lg:text-2xl font-normal">
                                    Everything in Essentials + Virtual Staging, Enhanced Visibility, Phone Support
                                </p>
                            </div>
                            <div className="text-center lg:text-right">
                                <h4 className="text-4xl md:text-6xl xl:text-7xl font-bold text-gray-900 mb-4 text-center">$599</h4>
                                <button className="px-6 py-2 text-lg md:text-xl lg:text-[22px] bg-blue-500 text-white rounded hover:bg-blue-600">
                                    Purchase Now
                                </button>
                            </div>
                        </div>

                        {/* Pricing Card 3 */}
                        <div className="bg-white p-6 md:p-10 lg:p-12 rounded-lg shadow-lg flex flex-col xl:flex-row gap-4 xl:gap-0 justify-between items-center">
                            <div className="w-full xl:w-1/2 mb-6 lg:mb-0">
                                <h3 className="text-2xl md:text-3xl lg:text-[34px] font-bold text-gray-900 mb-2">AI-Elite (Premium)</h3>
                                <p className="text-gray-600 text-lg md:text-xl lg:text-2xl font-normal">
                                    Everything in Pro + Predictive Market Analysis, Priority Listing, Dedicated Agent
                                </p>
                            </div>
                            <div className="text-center lg:text-right">
                                <h4 className="text-4xl md:text-6xl xl:text-7xl font-bold text-gray-900 mb-4 text-center">$999</h4>
                                <button className="px-6 py-2 text-lg md:text-xl lg:text-[22px] bg-blue-500 text-white rounded hover:bg-blue-600">
                                    Purchase Now
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Home;
