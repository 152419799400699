function TestimonialCard({ testimonial }) {
    return (
  
      <div className="p-12 grid grid-cols-1 md:grid-cols-3 gap-10 md:gap-12">
        {
          testimonial.map((items, index) => {
            return (
              <div key={index} className="flex flex-col gap-4 bg-[#F2F7FF] p-3 mb-4 rounded-lg border-[1px] border-[#E9ECF2] font-poppins font-normal">
                <div className="flex justify-between md:pl-4">
                  <div className="flex gap-3">
                    <img src={items.image} alt={`${items.name}'s profile`} className="w-12 h-12" />
                    <div className="flex flex-col gap-1">
                      <div className="text-[13.44px] ">
                        <h3 className="font-bold text-left">{items.name}</h3>
                        <p className="text-[#7D7A7A]">{items.location}</p>
                      </div>
                      <div className="flex space-x-0.5 text-[#758090]">
                        {Array.from({ length: items.rating }).map((_, index) => {
                          return (
                            <svg
                              key={index}
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentColor"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              className="w-3 h-3"
                            >
                              <path d="M12 17.27l5.18 2.73-1.64-7.03L21 8.91l-7.19-.61L12 2 10.19 8.3 3 8.91l5.46 4.06-1.64 7.03L12 17.27z" />
                            </svg>
                          )
                        })}
                      </div>
                    </div>
                  </div>
                  <p className={`${items.tagColor} text-[10px]`}>{items.tag}</p>
                </div>
                <div className="md:pl-4">
                  <p className="text-[14px] text-[#293238] text-left">{items.review}</p>
                </div>
              </div>
            )
          })
        }
  
      </div>
    );
  };
  export default TestimonialCard
  