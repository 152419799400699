import React from "react";
import cardLeftImage from "../Asserts/card-left.png";

const Section = ({ image, title, description }) => {
  return (
    <div className="flex flex-col md:flex-row items-center p-4 space-y-4 md:space-y-0 md:space-x-4">
      <div className=" w-full  md:w-1/2 flex justify-center">
        <img src={cardLeftImage} alt="illustration" className="w-64 h-auto xl:w-[60%]" />
      </div>
      <div className="w-full md:w-1/2 text-center md:text-left">
        {/* <h2 className="text-4xl font-roboto font-medium text-mytext2 pb-2">
          {title}
        </h2> */}
        <h2 className="text-4xl md:text-3xl sm:text-2xl font-roboto font-medium text-mytext2 pb-2">
  {title}
</h2>

        <p className="font-robot font-normal text-base mt-2 text-mytext3 ">{description}</p>
      </div>
    </div>
  );
};

const SectionOne = () => {
  return (
    <main className="bg-[#F2F7FF] p-10 ">
      <Section 
        image="/path-to-image-1.png"
        title="Light, Fast & Powerful"
        description={
          // <>
          // Lorem ipsum dolor sit amet, consectetuer adipiscing elit. <br />Aenean commodo ligula eget dolor. Aenean massa. Cum<br/> sociis natoque penatibus et magnis dis parturient montes,<br/> nascetur ridiculus 
          // mus.<br/> Donec quam felis, ultricies nec, pellentesque eu, pretium <br/>quis, sem. Nulla consequat massa quis enim.
          // </>
          <>
          Lorem ipsum dolor sit amet, consectetuer adipiscing elit. 
          <br className="hidden lg:inline " /> {/* Only break on large screens */}
          Aenean commodo ligula eget dolor. Aenean massa. Cum
          <br className="hidden lg:inline " /> {/* Only break on large screens */}
          sociis natoque penatibus et magnis dis parturient montes,
          <br className="hidden lg:inline  " /> {/* Only break on large screens */}
          nascetur ridiculus
          <br className="hidden lg:inline " /> 
          mus.Donec quam felis, ultricies nec, pellentesque eu, pretium 
          <span className="hidden lg:inline-block lg:pb-8"></span> {/* Add space below on large screens */}
          <br className="hidden lg:inline " /> {/* Only break on large screens */}
          quis, sem. Nulla consequat massa quis enim.
        </>
        }
         
      />
    </main>
  );
};

export default SectionOne;