import SectionOne from "../components/SectionOne";
import SectionThree from "../components/SectionThree";
import SectionTwo from "../components/SectionTwo";

const Home = () => {
    return (
        // <section className="py-16 bg-gray-100">
        //     <div className="container mx-auto px-4 lg:px-0">
        //         {/* Section Header */}
        //         <div className="text-center mb-12">
        //             <h3 className="font-rubik  text-xl font-normal text-orange-500 mb-1">How it works?</h3>
        //             <h2 className="font-rubik text-[40px] font-normal text-[#23214B]">Selling Your Home, Simplified</h2>
        //             <p className="font-rubik font-normal text-base text-[#222222] max-w-xl mx-auto mt-4">
        //                 Discover how it works with simplified AI tools to sell your home. These user-friendly technologies make the process more efficient by automating tasks, providing data-driven insights.
        //             </p>
        //         </div>

        //         {/* Feature Section 1 */}
        //         <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center mb-12">
        //             {/* Illustration */}
        //             <div className="flex justify-center lg:justify-start">
        //                 <img src="images/howitworks1.png" alt="Illustration 1" className="w-full max-w-xs lg:max-w-md" />
        //             </div>

        //             {/* Text */}
        //             <div>
        //                 <h3 className="text-roboto text-4xl font-medium text-[#091133] mb-4">Light, Fast & Powerful</h3>
        //                 <p className="text-roboto font-normal text-base text-[#6F7CB2] mb-6">
        //                     Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa.
        //                     Cum  sociis natoque penatibus et magnis dis parturient montes   nascetur ridiculus 
        //                     mus. Donec quam felis, ultricies nec,
        //                     pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim.
        //                 </p>
        //             </div>
        //         </div>

        //         {/* Feature Section 2 */}
        //         <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center mb-12">
        //             {/* Illustration */}
        //             <div className="flex justify-center lg:justify-start">
        //                 <img src="images/howitworks2.png" alt="Illustration 2" className="w-full max-w-xs lg:max-w-md" />
        //             </div>

        //             {/* Text */}
        //             <div>
        //             <h3 className="text-roboto text-4xl font-medium text-[#091133] mb-4">Light, Fast & Powerful</h3>
        //                 <p className="text-roboto font-normal text-base text-[#6F7CB2] mb-6">
        //                     Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa.
        //                     Cum  sociis natoque penatibus et magnis dis parturient montes   nascetur ridiculus 
        //                     mus. Donec quam felis, ultricies nec,
        //                     pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim.
        //                 </p>
        //             </div>
        //         </div>

        //         {/* Feature Section 3 */}
        //         <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
        //             {/* Illustration */}
        //             <div className="flex justify-center lg:justify-start">
        //                 <img src="images/howitworks3.png" alt="Illustration 3" className="w-full max-w-xs lg:max-w-md" />
        //             </div>

        //             {/* Text with Button */}
        //             <div>
        //             <h3 className="text-roboto text-4xl font-medium text-[#091133] mb-4">Light, Fast & Powerful</h3>
        //                 <p className="text-roboto font-normal text-base text-[#6F7CB2] mb-6">
        //                     Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa.
        //                     Cum  sociis natoque penatibus et magnis dis parturient montes   nascetur ridiculus 
        //                     mus. Donec quam felis, ultricies nec,
        //                     pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim.
        //                 </p>
        //                 <button className="font-roboto font-medium text-base w-[157px] h-[27px] px-6 py-0 bg-[#111B47] text-white rounded-lg hover:bg-blue-600">
        //                     Purchase Now
        //                 </button>
        //             </div>
        //         </div>
        //     </div>
        // </section>
        <>
    
        <main className=" main bg-[#F5F5F5] p-4">
          <div className="text-center my-8">
            <h1 className="font-rubik font-normal text-xl text-[#FF9357] mb-0">
              How it works?
            </h1>
            <h1 className=" font-rubik text-secondary font-normal text-[40px]">
              Selling Your Home, Simplified
            </h1>
            <p className="font-rubik text-myText text-base font-normal mt-2">
              Discover how it works with simplified AI tools to sell your home.
              These user-friendly
              <br className="" /> technologies make the process more efficient by
              automating tasks, providing data-driven insights.
            </p>
          </div>
       
        </main>
          <SectionOne/>
          <SectionTwo/>
          <SectionThree/> 
        </>
    );
};

export default Home;
